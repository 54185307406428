import React, {useState} from "react";
import {Container, ThemeProvider, Typography} from "@mui/material";
import {theme} from "../theme";
import {JsonForms} from "@jsonforms/react";
import {loginSchema, loginSchemaData, loginUISchema} from "../jsonforms/schemas/login.schema";
import {materialAppRenderers} from "../jsonforms/renderers/renderers";
import {materialCells} from "@jsonforms/material-renderers";

export const Login: React.FC = () => {

    const [loginData, setLoginData] = useState(loginSchemaData)

    const handleChange = ({data, errors}: any) => {
        setLoginData(data)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Typography variant={'h4'}>Login</Typography>
                <JsonForms schema={loginSchema}
                           uischema={loginUISchema}
                           data={loginData}
                           renderers={materialAppRenderers}
                           validationMode={'ValidateAndShow'}
                           cells={materialCells}
                           onChange={handleChange}
                />
            </Container>
        </ThemeProvider>)
}