import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#d6ae51'
        },
        secondary: {
            main: '#E33E7F'
        },
        background: {
            default: '#4a6361',
            paper: '#4a6361'
        },
        divider: '#d6ae51'
    },
    direction: "ltr",
    typography: {
        fontFamily: 'Junicode',
        fontSize: 16,
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: 'JunicodeBold'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fieldset: {

                    },
                    '&.Mui-error': {
                        borderColor: '#7e7b50 !important',
                        fieldset: {
                            borderColor: '#7e7b50 !important',

                        },
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Junicode'
                }
            }
        }
    }
})