import {UiSchemaBuilder} from "../uischema-builder";
import {Generate} from "@jsonforms/core";

export const loginSchemaData = {
    login: {
        email: '',
        password: '',
        rememberMe: false
    }
}

export const loginSchema = {
    type: 'object',
    required: ['login'],
    properties: {
        login: {
            type: 'object',
            required: ['email', 'password'],
            properties: {
                email: {
                    type: 'string',
                    title: 'Email',
                    description: 'Email',
                },
                password: {
                    type: 'string',
                    title: 'Mot de passe',
                    description: 'Mot de passe',
                },
                rememberMe: {
                    type: 'boolean',
                    title: 'Se souvenir de moi',
                    description: 'Se souvenir de moi'
                }
            }
        }
    }
}

const uiSchemaBuilder = new UiSchemaBuilder(
    loginSchema.properties.login.properties,
    'login',
    undefined,
    'VerticalLayout'
)

export const loginUISchema = {
    type: 'VerticalLayout',
    scope: '#/properties/login',
    elements: uiSchemaBuilder.schemaElements
}