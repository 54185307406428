import React, {useCallback, useState} from "react";
import {Box, Button, CircularProgress, Grid, TextField, ThemeProvider, Typography} from "@mui/material";
import {theme} from "../../theme";
import {useNewsletterContext} from "../../context/newsletter.context";
import {debounce, isEmpty} from "lodash";
import Joi from 'joi'
import {FactCheck} from "@mui/icons-material";

const emailSchema = Joi.string().email({tlds: {allow: false}}).messages({'string.email': 'L\'email est invalide'}).required()
export const Newsletter: React.FC = () => {

    const {sendNewsletter, successes} = useNewsletterContext()
    const [loading, setIsLoading] = useState(false)

    const [email, setEmail] = useState('')
    const handleOnClick = async () => {
        setIsLoading(true)
        await sendNewsletter(email)
        setIsLoading(false)
    }

    const handleOnInput = (value: string) => {
        setEmail(value)
    }

    const debounceHandleOnClick = useCallback(debounce(handleOnClick, 500), [email])

    return (
        <ThemeProvider theme={theme}>

            <Box>

                <Typography variant={'h4'}>Thérapeute et membre superviseur de l'Association française de Thérapie Comportementale & Cognitive (AFTCC) en plus d'un doctorat en médecine,
                    j'ai décidé cette chaine pour former les personnes qui s'intéressent à la psychologie à mieux se connaitre pour une meilleure gestion de soi, de sa vie et de ses projets.
                    Laissez moi votre email et gardons contact !</Typography>
            </Box>
            {successes ? <Box>
                <Grid container sx={{'alignItems': 'center'}}>
                    <Grid item>
                        <FactCheck color={"primary"} sx={{width: '300px', height: '300px'}}/>
                    </Grid>
                    <Grid>
                        <Typography variant={"h4"}>{successes.toString().split('#@#')[0]}</Typography>
                    </Grid>
                </Grid>
            </Box> : <Box>
                <Typography sx={{color: '#d6ae51', fontSize: '21px', letterSpacing: '2px', textShadow: '1px 1px 1px black'}}>Entrez votre email</Typography>

                <Box sx={{display: 'flex'}}>
                    <TextField error={!isEmpty(email) && emailSchema.validate(email).error !== undefined} sx={{width: '360px'}} onChange={event => {
                        handleOnInput(event.target.value)
                    }}></TextField>
                    <Button onClick={debounceHandleOnClick} disabled={isEmpty(email) || emailSchema.validate(email).error !== undefined}>
                        {loading ? <CircularProgress/> : 'S\'abonner'}

                    </Button>

                </Box>
                <Typography color={'#7e7b50'} variant={"h6"}>{!isEmpty(email) && emailSchema.validate(email).error?.message}</Typography>
            </Box>}

        </ThemeProvider>
    )
}