import React, {useEffect, useState} from "react";
import {Box, Container, IconButton} from "@mui/material";
import logoImage from "../../assets/PSKT_logo_transparent.png";
import {Newsletter} from "../newsletter/newsletter";
import {useNewsletterContext} from "../../context/newsletter.context";

export const Home: React.FC = () => {

    return (
        <Container>
            <Newsletter/>
        </Container>

    )
}