import React from "react";
import {ThemeProvider} from "@mui/material";
import {theme} from "../../theme";
import {StickyHeadTable} from "../../components/tables/list-table";
import {useNewsletterContext} from "../../context/newsletter.context";
import {Column} from "../../config/interfaces/tables.interface";
import {NewsletterInterface} from "../../models/Newsletter";

interface ListNewslettersInterface {
    active: boolean
}

const columns: readonly Column[] = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'name', label: 'Nom', minWidth: 170 },
    {
        id: 'subject',
        label: 'Sujet',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'modificationDate',
        label: 'Date de modification',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'active',
        label: 'Active',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 100,
        align: 'right'
    }
];

export const ListNewsletters: React.FC<ListNewslettersInterface> = (props) => {

    const {active} = props

    const {allNewsletters, activeNewsletters, deleteNewsletters, createUpdateNewsletter} = useNewsletterContext()
    let allNews = allNewsletters as NewsletterInterface[]
    let activeNews = activeNewsletters as NewsletterInterface[]

    return (<ThemeProvider theme={theme}>
        <StickyHeadTable rows={active ? (activeNews) : allNews} columns={columns} functions={[deleteNewsletters, createUpdateNewsletter]}/>
    </ThemeProvider>)
}