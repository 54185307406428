import React, {useEffect} from "react";
import {NewsletterInterface} from "../../models/Newsletter";
import {Container, ThemeProvider} from "@mui/material";
import {theme} from "../../theme";
import {useNewsletterContext} from "../../context/newsletter.context";
import {CreateUpdateNewsletter} from "./create-update-newsletter";
import {ListNewsletters} from "./list-newsletters";

export const Newsletters: React.FC = () => {

    const {fetchAllNewsletters, allNewsletters} = useNewsletterContext()

    useEffect(() => {
        fetchAllNewsletters()
    }, [])

    useEffect(() => {
    }, [allNewsletters])
    return (<ThemeProvider theme={theme}>
        <Container>
            <CreateUpdateNewsletter />
        </Container>
        <Container>
            <ListNewsletters active={false}/>
        </Container>
    </ThemeProvider>)
}