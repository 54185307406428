import React, {useEffect, useState} from "react";
import {Alert, Box, Snackbar, SnackbarOrigin} from "@mui/material";
import {isEmpty} from "lodash";
import {SnackbarProps} from "./snackbar-props";
import {useNewsletterContext} from "../../context/newsletter.context";

interface SnackbarState extends SnackbarOrigin {
    open: boolean
}
export const SnackbarSuccess: React.FC<SnackbarProps> = (props) => {

    const {applySuccesses} = useNewsletterContext()
    const {message} = props

    const [state, setState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    })

    const {open, vertical, horizontal} = state

    const handleClose = () => {
        setState({...state, open: false})
        applySuccesses('')
    }

    useEffect(() => {
        if (!isEmpty(message)) {
            setState({...state, open: true})
        }
    }, [message])
    return (
        <Box sx={{width: 500}}>
            {!isEmpty(message) && (
                <Snackbar
                    open={open}
                    anchorOrigin={{vertical, horizontal}}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    key={message}
                >
                    <Alert onClose={handleClose} severity={'success'} sx={{width: '100%'}}>
                        {message.toString().split('#@#')[0]}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    )
}