import {createContext, useContext, useMemo, useState} from "react";
import {NewsletterService} from "../services/newsletter/newsletter.service";
import {isEmpty} from "lodash";
import {NewsletterInterface} from "../models/Newsletter";

export interface NewsletterContextType {
    applyErrors: (errors: string) => void
    applySuccesses: (successes: string) => void
    errors: any
    successes: any
    allNewsletters: NewsletterInterface[] |  undefined
    activeNewsletters: NewsletterInterface[] | undefined
    newsletterService: NewsletterService
    sendNewsletter: (to: string) => void
    fetchActiveNewsletters: () => void
    fetchAllNewsletters: () => void
    fetchOneNewsletter: (newsletterId: string) => void
    createUpdateNewsletter: (newsletter:  NewsletterInterface)  => void
    deleteNewsletters: (newsletter:  NewsletterInterface)  => void
}

const NewsletterContext = createContext<NewsletterContextType>({
    applyErrors: () => {},
    applySuccesses: () => {},
    errors: null,
    successes: null,
    allNewsletters:  undefined,
    activeNewsletters: undefined,
    newsletterService: new NewsletterService(),
    sendNewsletter: (to: string) => {},
    fetchActiveNewsletters: () => {},
    fetchAllNewsletters: () => {},
    fetchOneNewsletter: (newsletterId: string) => {},
    createUpdateNewsletter: (newsletter:  NewsletterInterface)  => {},
    deleteNewsletters: (newsletter:  NewsletterInterface)  => {}
})

export const useNewsletterContext = () => useContext(NewsletterContext)

interface NewsletterContextProps {
    children: any
}

const NewsletterProvider = (children: NewsletterContextProps) => {
    const [errors, setErrors] = useState<string>('')
    const [successes, setSuccesses] = useState<string>('')
    const [allNewsletters, setAllNewsletters] = useState<NewsletterInterface[]>()
    const [activeNewsletters, setActiveNewsletters] = useState<NewsletterInterface[]>()

    const newsletterService = new NewsletterService()


    const sendNewsletter = async (to: string) => {
        const sent = await newsletterService.sendNewsletter(to)

        if (!sent) {
            applyErrors(newsletterService.newsletterError?.message as string)
        } else {
            applySuccesses(newsletterService.newsletterSuccess as string)
        }
    }

    const createUpdateNewsletter = async (newsletter: NewsletterInterface) => {
        const createUpdateNewsletter = await newsletterService.createUpdateNewsletter(newsletter)

        if (!createUpdateNewsletter) {
            applyErrors(newsletterService.newsletterError?.message as string)
        } else {
            applySuccesses(newsletterService.newsletterSuccess as string)
        }

        await fetchAllNewsletters()
    }

    const fetchAllNewsletters = async () => {
        const fetchAllNewsletter = await newsletterService.fetchAllNewsletters()

        if (!fetchAllNewsletter) {
            applyErrors(newsletterService.newsletterError?.message as string)
        } else {
            applyAllNewsletters(newsletterService.parsedResponse?.newsletters as NewsletterInterface[])
            applySuccesses(newsletterService.newsletterSuccess as string)
        }
    }

    const fetchActiveNewsletters = async () => {
        const fetchActiveNewsletters = await newsletterService.fetchActiveNewsletters()

        if (!fetchActiveNewsletters) {
            applyErrors(newsletterService.newsletterError?.message as string)
        } else {
            applyActiveNewsletters(newsletterService.parsedResponse?.newsletters as NewsletterInterface[])
            applySuccesses(newsletterService.newsletterSuccess as string)
        }
    }

    const fetchOneNewsletter = async (newsletterId: string) => {
        const fetchOneNewsletter = await newsletterService.fetchOneNewsletter(newsletterId)

        if (!fetchOneNewsletter) {
            applyErrors(newsletterService.newsletterError?.message as string)
        } else {
            applySuccesses(newsletterService.newsletterSuccess as string)
        }
    }

    const deleteNewsletters = async (newsletter: NewsletterInterface) => {
        const deleteNewsletter = await newsletterService.deleteNewsletters(newsletter)

        if (!deleteNewsletter) {
            applyErrors(newsletterService.newsletterError?.message as string)
        } else {
            applySuccesses(newsletterService.newsletterSuccess as string)
        }
        await fetchAllNewsletters()
    }

    const applyErrors = (errors: string) => {
        if (!isEmpty(errors)) {
            errors += '#@#' + Math.random()
        }
        setErrors(errors)
    }
    const applySuccesses = (successes: string) => {
        if (!isEmpty(successes)) {
            successes += '#@#' + Math.random()
        }
        setSuccesses(successes)
    }

    const applyAllNewsletters = (newsletters: NewsletterInterface[]) => {
        setAllNewsletters(newsletters)
    }

    const applyActiveNewsletters = (newsletters: NewsletterInterface[]) => {
        setActiveNewsletters(newsletters)
    }

    const NewsletterState = useMemo(() => {
        return {
            applyErrors,
            applySuccesses,
            sendNewsletter,
            createUpdateNewsletter,
            fetchAllNewsletters,
            fetchActiveNewsletters,
            fetchOneNewsletter,
            deleteNewsletters,
            applyAllNewsletters,
            applyActiveNewsletters,
            allNewsletters,
            activeNewsletters,
            errors,
            successes,
            newsletterService
        }
    }, [errors, successes])

    return (
        <NewsletterContext.Provider value={NewsletterState}>
            {children.children}
        </NewsletterContext.Provider>
    )
}

export default NewsletterProvider