import {JsonSchemaPropertiesConfig} from "./properties-types";

interface ElementInterface {
    type: string
    elements: any[]
}

interface uiSchemaStages {
    stage: number
    numberOfElements: number
}

export class UiSchemaBuilder {
    public schemaProperties: any
    public stages: uiSchemaStages[]
    public prefix: string
    public element: ElementInterface = {
        type: 'HorizontalLayout',
        elements: [],
    }
    public schemaElements: any[] = []

    constructor(
        public inputSchema: any,
        public schemaPrefix: string,
        public rules?: any,
        public layout?: string,
    ) {
        this.prefix = '#/properties/' + schemaPrefix + '/properties/'
        if (layout) this.element.type = layout
        this.schemaProperties = inputSchema
        const tmpStg = []
        const numberOfElements = Object.keys(this.schemaProperties).length
        let i = 1
        let numberOfStages = 0
        let elementsPerModuloRound = 0
        while (i < numberOfElements + 1) {
            elementsPerModuloRound++
            if (i % 4 === 0) {
                tmpStg.push({stage: numberOfStages, numberOfElements: 4})
                numberOfStages++
                elementsPerModuloRound = 0
            }
            i++
        }

        tmpStg.push({
            stage: numberOfStages,
            numberOfElements: elementsPerModuloRound - 1,
        })
        this.stages = tmpStg
        if (rules) {
            this.buildUiSchema(rules)
        } else {
            this.buildUiSchema()
        }
    }

    public buildUiSchema(rules?: any): void {
        let stageIndex = 0
        let currentNumberOfElementsPerStage = 0
        let currentStage: uiSchemaStages = this.stages[stageIndex]
        let componentsArray: any = undefined
        const schemaProperties = Object.keys(this.schemaProperties)
        schemaProperties.forEach((key: string) => {
            let property
            if (key === 'components') {
                const components = this.schemaProperties[key]
                const componentItems = components.items
                const componentsProperties = componentItems.properties
                componentsArray = componentsProperties
            } else {
                const definedType = JsonSchemaPropertiesConfig.filter(
                    (f: any) => f.label === key,
                )[0]
                if (rules) {
                    const rule = rules.filter((f: any) => f.key === key)[0]
                    property = {
                        type: definedType.type === 'file' ? 'file': 'Control',
                        scope: this.prefix + key,
                        options: {
                            type: definedType
                                ? definedType.type
                                : this.schemaProperties[key].type,
                            customClass: this.schemaPrefix,
                        },
                        rule: rule?.rule,
                    }
                } else {
                    property = {
                        type: definedType.type === 'file' ? 'file': 'Control',
                        scope: this.prefix + key,
                        options: {
                            type: definedType
                                ? definedType.type
                                : this.schemaProperties[key].type,
                            customClass: this.schemaPrefix,
                            multi: this.schemaProperties[key].multi ? this.schemaProperties[key].multi : false
                        },
                    }
                }
                this.element.elements.push(property)

                if (currentStage &&
                    currentNumberOfElementsPerStage === currentStage.numberOfElements - 1
                ) {
                    currentStage = this.stages[++stageIndex]
                    this.schemaElements.push({...this.element})
                    this.element.elements = []
                    currentNumberOfElementsPerStage = 0
                } else {
                    currentNumberOfElementsPerStage++
                }
            }
        })

        this.schemaElements.push({...this.element})
        if (componentsArray) {
            this.element.elements = []
            let property
            property = {
                type: 'Control',
                scope: this.prefix + 'components',
            }
            this.element.elements.push(property)
            this.schemaElements.push({...this.element})
        }
    }
}


