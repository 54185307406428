import React, {useCallback, useEffect, useState} from "react";
import {Button, CircularProgress, ThemeProvider, Typography} from "@mui/material";
import {theme} from "../../theme";
import {JsonForms} from "@jsonforms/react";
import {materialAppRenderers} from "../../jsonforms/renderers/renderers";
import {materialCells} from "@jsonforms/material-renderers";
import {newsletterSchema, newsletterSchemaData, newsletterUISchema} from "../../jsonforms/schemas/newsletter.schema";
import {NewsletterInterface, newsletterSchemaToValidate} from "../../models/Newsletter";
import {debounce, isEmpty} from "lodash";
import {useNewsletterContext} from "../../context/newsletter.context";
import Dropzone, {useDropzone} from "react-dropzone";

export const CreateUpdateNewsletter: React.FC<NewsletterInterface> = (props) => {

    const {htmlContent} = props
    const {createUpdateNewsletter, successes} = useNewsletterContext()
    const [newsletterData, setNewsletterData] = useState(!isEmpty(htmlContent) ? {newsletter: props} : newsletterSchemaData)

    const [loading, setIsLoading] = useState(false)

    const handleChange = ({data, errors}: any) => {
        setNewsletterData(data)
    }

    const handleOnClick = async () => {
        setIsLoading(true)
        await createUpdateNewsletter(newsletterData.newsletter)
        setIsLoading(false)
    }

    useEffect(() => {
        if (!isEmpty(successes)) {
            setNewsletterData(newsletterSchemaData)
        }
    }, [successes])

    const debounceHandleOnClick = useCallback(debounce(handleOnClick, 500), [newsletterData])

    return (
        <ThemeProvider theme={theme}>

            <Typography variant={'h4'}>Créer / Modifier une newsletter</Typography>
            <JsonForms
                schema={newsletterSchema}
                       uischema={newsletterUISchema}
                       data={newsletterData}
                       renderers={materialAppRenderers}
                       validationMode={'ValidateAndShow'}
                       cells={materialCells}
                       onChange={handleChange}
            />

            <Button onClick={debounceHandleOnClick} disabled={isEmpty(newsletterData) || newsletterSchemaToValidate.validate(newsletterData?.newsletter).error !== undefined}>
                {loading ? <CircularProgress/> : 'Valider'}
            </Button>
        </ThemeProvider>
    )
}