import Joi from "joi";

export interface NewsletterInterface {
    _id?: string;
    id?: string;
    subject?: string;
    htmlContent?: File[];
    creationDate?: string;
    modificationDate?: string;
    active?: boolean
    name?: string
}

export interface NewsletterResponseInterface {
    message: string
    newsletters?: NewsletterInterface[]
    newsletter?: NewsletterInterface
}

export interface SentNewsletterInterface {
    _id?: string;
    id: string;
    to: string;
    newsletterId: string
    creationDate: string;
}

export const newsletterSchemaToValidate = Joi.object({
    name: Joi.string().min(2).max(30).required(),
    subject: Joi.string().min(2).max(200).required(),
    htmlContent: Joi.array().required(),
    active: Joi.boolean()
})

export const newsletterSchemaToUpdate = Joi.object({
    name: Joi.string().min(2).max(30).required(),
    subject: Joi.string().min(2).max(200).required(),
    htmlContent: Joi.string().min(2).required(),
    active: Joi.boolean()
})
