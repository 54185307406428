import {BaseResponse, BaseService, ErrorResponse} from "../base.service";
import {ServerConfig} from "../../config/server.config";
import {NewsletterInterface, NewsletterResponseInterface} from "../../models/Newsletter";

export class NewsletterService extends BaseService {

    public newsletterError: ErrorResponse | undefined
    public newsletterSuccess: string | undefined
    public parsedResponse: NewsletterResponseInterface | undefined
    constructor() {
        super();
    }

    public handleResponse(createUpdateNewsletter: any): boolean {
        const response: BaseResponse = this.parseResponse(createUpdateNewsletter)
        if (response.status >= 400) {
            this.newsletterSuccess = ''
            this.newsletterError = response.errorResponse
            return false
        }

        this.newsletterError = {}
        this.newsletterSuccess = response.data.message
        this.parsedResponse = {
            newsletter: response.data.newsletter,
            newsletters: response.data.newsletters,
            message: response.data.message
        }
        return true
    }
    public async sendNewsletter(to: string): Promise<boolean> {
        try {
            const sendNewsletterRequest = await this.axiosInstance.post(ServerConfig.SEND_NEWSLETTER_URL(), JSON.stringify({to: to}), {headers: {'Content-Type': 'application/json'}})
            return this.handleResponse(sendNewsletterRequest)
        } catch (e) {
            return false
        }
    }

    public async commonUpdateNewsletters(newsletter: NewsletterInterface): Promise<boolean> {
        try {
            let newsletterParam = Object.assign({}, newsletter)
            const updatedNewsletter = await this.axiosInstance.put(ServerConfig.DELETE_NEWSLETTER, JSON.stringify(newsletterParam), {headers: {'Content-Type': 'application/json'}})
            return this.handleResponse(updatedNewsletter)
        } catch (e) {
            return false
        }
    }

    public async createUpdateNewsletter(newsletter: NewsletterInterface): Promise<boolean> {
        try {
            const {htmlContent, ...news} = newsletter
            let newsletterParam = Object.assign({}, newsletter)
            let createUpdateNewsletter
            const formData = new FormData()
            let content = htmlContent as File[]
            formData.append('htmlContent', content[0] as File)
            formData.append('form', JSON.stringify(newsletter))
            if (newsletterParam._id) {
                createUpdateNewsletter = await this.axiosInstance.put(ServerConfig.CREATE_UPDATE_NEWSLETTER_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            } else {
                createUpdateNewsletter = await this.axiosInstance.post(ServerConfig.CREATE_UPDATE_NEWSLETTER_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            }
            return this.handleResponse(createUpdateNewsletter)
        } catch (e) {
            return false
        }
    }

    public async fetchAllNewsletters(): Promise<boolean> {
        try {
            const fetchAllNewsletters = await this.axiosInstance.get(ServerConfig.FETCH_ALL_NEWSLETTERS_URL, {headers: {'Content-Type': 'application/json'}})
            return this.handleResponse(fetchAllNewsletters)
        } catch (e) {
            return false
        }
    }

    public async fetchActiveNewsletters(): Promise<boolean> {
        try {
            const activeNewsletters = await this.axiosInstance.get(ServerConfig.FETCH_NEWSLETTERS_URL, {headers: {'Content-Type': 'application/json'}})
            return this.handleResponse(activeNewsletters)
        } catch (e) {
            return false
        }
    }

    public async fetchOneNewsletter(newsletterId: string): Promise<boolean> {
        try {
            const activeNewsletters = await this.axiosInstance.get(ServerConfig.FETCH_ONE_NEWSLETTER_URL(newsletterId), {headers: {'Content-Type': 'application/json'}})
            return this.handleResponse(activeNewsletters)
        } catch (e) {
            return false
        }
    }

    public async deleteNewsletters(newsletter: NewsletterInterface): Promise<boolean> {
        try {
            let newsletterParam = Object.assign({}, newsletter)
            const deletedNewsletters = await this.axiosInstance.put(ServerConfig.DELETE_NEWSLETTER, JSON.stringify(newsletterParam), {headers: {'Content-Type': 'application/json'}})
            return this.handleResponse(deletedNewsletters)
        } catch (e) {
            return false
        }
    }
}