interface propertiesConfig {
    label: string
    type: string
}
export const JsonSchemaPropertiesConfig: propertiesConfig[] = [
    {
        label: 'email',
        type: 'text'
    },
    {
        label: 'password',
        type: 'text'
    },
    {
        label: 'rememberMe',
        type: 'boolean'
    },
    {
        label: 'name',
        type: 'text'
    },
    {
        label: 'htmlContent',
        type: 'file'
    },
    {
        label: 'subject',
        type: 'text'
    },
    {
        label: 'active',
        type: 'boolean'
    }
]