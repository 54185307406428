// import React from "react";
// import {Box, Container, IconButton, Typography} from "@mui/material";
// import logoImage from "../../assets/PSKT_logo_transparent.png";
// import {Link} from "react-router-dom";
//


import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {LoginSharp} from "@mui/icons-material";
import {ThemeProvider, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {theme} from "../../theme";
import {routes} from "../../config/routes";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const styleBoxContainer = {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '100%',
    borderRadius: '12px',
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    boxShadow: 'none',
    opacity: 1,
    display: 'flex',
    mozboxPack: 'justify',
    justifyContent: 'space-between',
    alignItems: 'center',
}
// export const Header: React.FC = () => {
//     return (
//         <Container style={{position: 'sticky'}}>
//             <Box py={1} px={1.5} sx={styleBoxContainer}>
//                 <Box display={'flex'} sx={{alignItems: 'center'}}>
//                     <IconButton style={{
//                         width: '100px',
//                         height: '100px',
//                         background: `url(${logoImage}) no-repeat`,
//                         backgroundSize: 'contain',
//                         borderRadius: 'unset',
//                     }}>
//                     </IconButton>
//                     <Box>
//                         <Typography color={'#d6ae51'} variant={'h5'}>
//                             <Link style={{color: 'inherit', textDecoration: 'none', fontFamily: 'Junicode'}} to={'/login'}>
//                                 Connexion
//                             </Link>
//                         </Typography>
//                     </Box>
//                 </Box>
//
//             </Box>
//         </Container>
//     )
// }

export const Header: React.FC = () => {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {routes.map((route, index) => {
                    return (
                        <ListItem key={route.key} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {route.icon}
                                </ListItemIcon>

                                <Typography variant={'h5'}>
                                    <Link style={{color: 'inherit', textDecoration: 'none', fontFamily: 'Junicode'}} to={route.path}>
                                        {route.name}
                                    </Link>
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            <React.Fragment key={'left'}>
                <Button onClick={toggleDrawer('left', true)}>{'Menu'}</Button>
                <Drawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                >
                    {list('left')}
                </Drawer>
            </React.Fragment>
            {/*{(['left', 'right', 'top', 'bottom'] as const).map((anchor) => (*/}
            {/*    <React.Fragment key={anchor}>*/}
            {/*        <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
            {/*        <Drawer*/}
            {/*            anchor={anchor}*/}
            {/*            open={state[anchor]}*/}
            {/*            onClose={toggleDrawer(anchor, false)}*/}
            {/*        >*/}
            {/*            {list(anchor)}*/}
            {/*        </Drawer>*/}
            {/*    </React.Fragment>*/}
            {/*))}*/}
        </ThemeProvider>
    );
}