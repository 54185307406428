import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {HomeMini, LoginSharp} from "@mui/icons-material";
import NewsletterProvider from "./context/newsletter.context";
import {Home} from "./layouts/home/Home";
import {Statuses} from "./layouts/statuses/Statuses";
import {Login} from "./auth/Login";
import {Header} from "./layouts/header/Header";
import {Newsletters} from "./admin/newsletters/newsletters";


const compose = (providers: any[]) => {
    return providers.reduce((Prev, Curr) => (children: any) => {
        return <Prev>
            <Curr>{children}</Curr>
        </Prev>
    })
}

const Providers = compose([NewsletterProvider])

function App() {
    return (
        <Providers>
            <div style={{width: window.innerWidth, height: window.innerHeight, backgroundColor: '#4a6361'}}>
                <Statuses/>
                <Header/>
                <Routes>
                    <Route path="/" Component={Home}/>
                    <Route path="/login" Component={Login}/>
                    <Route path="/newsletter" Component={Newsletters}/>
                    {/* Add other routes here */}
                </Routes>
            </div>
        </Providers>
    );
}

export default App;
