import {HomeMini, HomeSharp, LoginSharp, NewspaperSharp} from "@mui/icons-material";
import React from "react";

export const routes = [
    {
        name: "Connexion",
        key: "login",
        icon: <LoginSharp/>,
        path: '/login'
    },
    {
        name: "Accueil",
        key: "home",
        icon: <HomeSharp/>,
        path: '/'
    },
    {
        name: "Newsletter",
        key: "newsletter",
        icon: <NewspaperSharp/>,
        path: '/newsletter'
    },
]
