export interface ServerConfig {
    BASE_URL: string | undefined
    SEND_NEWSLETTER_URL: () => string
    CREATE_UPDATE_NEWSLETTER_URL: string
    FETCH_NEWSLETTERS_URL: string
    FETCH_ALL_NEWSLETTERS_URL: string
    FETCH_ONE_NEWSLETTER_URL: (newsletterId: string) => string
    DELETE_NEWSLETTER: string
    TIMEOUT: number
}

export const ServerConfig: ServerConfig = {
    BASE_URL: 'https://www.psyko-the.com',
    SEND_NEWSLETTER_URL: () => {
        return '/api/newsletter/send'
    },
    CREATE_UPDATE_NEWSLETTER_URL: '/api/newsletter/',
    FETCH_NEWSLETTERS_URL: '/api/newsletter',
    FETCH_ALL_NEWSLETTERS_URL: '/api/newsletter/allNewsletters',
    FETCH_ONE_NEWSLETTER_URL: (queryParam: string) => {
        return `/api/newsletter/${queryParam}`
    },
    DELETE_NEWSLETTER: '/api/newsletter/delete',
    TIMEOUT: 10000
}