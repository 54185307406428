import React, {useCallback, useState} from "react";
import {ControlProps} from "@jsonforms/core";
import {withJsonFormsControlProps} from '@jsonforms/react'
import {useDropzone} from "react-dropzone";
import {Box, Typography} from "@mui/material";
import {FileUploadSharp} from "@mui/icons-material";

const classCss = {
    '&:hover': {
        background: "#f00",
    },
}
const UploadFileControl: React.FC<ControlProps> = (props) => {

    const [dropped, setDropped] = useState(false)
    const {handleChange, path} = props
    const onDrop = useCallback((acceptedFiles: any) => {
        setDropped(true)
        handleChange(path, acceptedFiles)
        // Do something with the files
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div className={`dnd ${isDragActive ? 'dndActive' : ''}`}  {...getRootProps()} style={{height: '250px', position: 'relative', cursor: 'pointer', borderRadius: '5px'}}>
            <input {...getInputProps()} />
            <Box sx={{position: 'relative', height: '100%'}}>
                <Box sx={{position: 'absolute', transform: 'translateX(-50%) translateY(-50%)', top: '50%', left: '50%', textAlign: 'center'}}>
                    <FileUploadSharp color={'primary'} sx={{textShadow: '1px 1px 1px black'}}/>
                    {
                        !isDragActive && !dropped ?
                        <Typography sx={{color: '#d6ae51', fontSize: '21px', letterSpacing: '2px', textShadow: '1px 1px 1px black'}}>
                            Uploader la newsletter en HTML
                        </Typography>: ''
                    }
                    {
                        isDragActive && !dropped ?
                            <Typography sx={{color: '#d6ae51', fontSize: '21px', letterSpacing: '2px', textShadow: '1px 1px 1px black'}}>
                                Droppez ça ici...
                            </Typography> : ''
                    }
                    {
                        isDragActive && dropped ?
                            <Typography sx={{color: '#d6ae51', fontSize: '21px', letterSpacing: '2px', textShadow: '1px 1px 1px black'}}>
                                Add another file ...
                            </Typography>: ''
                    }
                    {
                        !isDragActive && dropped ?
                            <Typography sx={{color: '#d6ae51', fontSize: '21px', letterSpacing: '2px', textShadow: '1px 1px 1px black'}}>
                                {props.data ? props.data[0]?.name : ''}
                            </Typography> : ''
                    }

                </Box>
            </Box>

        </div>
    )
}

export default withJsonFormsControlProps(UploadFileControl)