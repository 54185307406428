import {UiSchemaBuilder} from "../uischema-builder";
import {Generate} from "@jsonforms/core";

export const newsletterSchemaData = {
    newsletter: {
        name: '',
        subject: '',
        active: false
    }
}

export const newsletterSchema = {
    type: 'object',
    required: ['newsletter'],
    properties: {
        newsletter: {
            type: 'object',
            required: ['name', 'htmlContent', 'subject'],
            properties: {
                name: {
                    type: 'string',
                    title: 'Libellé',
                    description: 'Libellé',
                    maxLength: 30
                },
                htmlContent: {
                    type: 'array',
                    title: 'Contenu',
                    description: 'Contenu',
                    multi: true
                },
                subject: {
                    type: 'string',
                    title: 'Sujet',
                    description: 'Sujet',
                },
                active: {
                    type: 'boolean',
                    title: 'Principale newsletter (campagne)',
                    description: 'Principale newsletter (campagne)'
                }
            }
        }
    }
}

const uiSchemaBuilder = new UiSchemaBuilder(
    newsletterSchema.properties.newsletter.properties,
    'newsletter',
    undefined,
    'VerticalLayout'
)

export const newsletterUISchema = {
    type: 'VerticalLayout',
    scope: '#/properties/newsletter',
    elements: uiSchemaBuilder.schemaElements
}