import {Axios, AxiosError, AxiosRequestConfig, AxiosHeaders} from 'axios'
import {ServerConfig} from "../config/server.config";
import {isEmpty} from "lodash";

export interface ErrorResponse {
    error_code?: string
    error_line?: string
    error_type?: string
    file_name?: string
    line_number?: number
    offset?: number
    sentry_link?: any
    status_code?: number
    tag?: string
    task_data?: any
    task_id?: string
    task_name?: string
    task_trace?: any
    message?: string
}

export interface BaseResponse {
    config: any
    data: any
    headers: AxiosHeaders
    request: XMLHttpRequest
    status: number
    statusText: string
    errorResponse: ErrorResponse
}

const AXIOS_BASE_CONFIG: AxiosRequestConfig = {
    baseURL: ServerConfig.BASE_URL as string,
    timeout: ServerConfig.TIMEOUT
}
export class BaseService {
    public axiosInstance: Axios
    public errors: any
    public data: any

    constructor() {
        const config = {
            ...AXIOS_BASE_CONFIG
        }
        this.axiosInstance = new Axios(config)

        this.axiosInstance.interceptors.response.use(
            response => {
                if (response.status === 500) {
                    const errorReturn: ErrorResponse = JSON.parse(response.data) as ErrorResponse
                    this.errors = errorReturn.error_code
                }
                return response
            }, error => {
                return error
            }
        )
    }

    public parseResponse(result: any): BaseResponse {
        let preResponse: any = {}
        if (result.status && typeof result.status === 'number') {
            preResponse.status = result.status
        }

        if (!isEmpty(result.data) && typeof result.data === 'string') {
            const d = JSON.parse(result.data as unknown as string)
            if (!isEmpty(d) && (result.status === 200 || result.status === 201)) {
                preResponse.data = d
            } else {
                preResponse.errorResponse = Object.assign({}, d)
            }
        }

        if (!isEmpty(result.headers) && typeof result.headers === 'object') {
            preResponse.headers = Object.assign({}, result.headers)
        }

        if (!isEmpty(result.request) && typeof result.request === 'object') {
            preResponse.request = Object.assign({}, result.request)
        }

        if (!isEmpty(result.config) && typeof result.config === 'object') {
            preResponse.config = Object.assign({}, result.config)
        }

        if (!isEmpty(result.statusText) && typeof result.statusText === 'string') {
            preResponse.statusText = result.statusText
        }

        const response: BaseResponse = Object.assign({}, preResponse)
        return response
    }

    public isAxiosError(error: any): boolean {
        const axiosError = new AxiosError(error)
        if (axiosError.isAxiosError) {
            console.log('AxiosError => ', axiosError)
            return axiosError.isAxiosError
        }
        return false
    }

}