import React, {useEffect, useState} from "react";
import {useNewsletterContext} from "../../context/newsletter.context";
import {isEmpty} from "lodash";
import {SnackbarError} from "../../components/snackbars/snackbar-error";
import {SnackbarSuccess} from "../../components/snackbars/snackbar-success";

export const Statuses: React.FC = () => {

    const [snackbar, setSnackbar] = useState<any>(undefined)
    const {errors, successes} = useNewsletterContext()

    useEffect(() => {
        if (!isEmpty(errors)) {
            setSnackbar(<SnackbarError message={errors}/>)
        }
    }, [errors])

    useEffect(() => {
        if (!isEmpty(successes)) {
            setSnackbar(<SnackbarSuccess message={successes}/>)
        }
    }, [successes])
    return (
        <>
            {snackbar}
        </>
    )
}