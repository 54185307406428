import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {TablesInterface} from "../../config/interfaces/tables.interface";
import {Box, ThemeProvider, Typography} from "@mui/material";
import {theme} from "../../theme";
import {NewsletterInterface} from "../../models/Newsletter";
import {DeleteSharp, NotificationsActiveSharp, UpdateSharp} from "@mui/icons-material";
import {MouseEventHandler} from "react";

export const StickyHeadTable: React.FC<TablesInterface> = (props) => {

    const {columns, rows, dataType, functions} = props
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDelete = async (newsletter: NewsletterInterface) => {
        if (functions) {
            const deleteFunc = functions[0]
            await deleteFunc(newsletter)
        }
    }

    const handleActivate = async (newsletter: NewsletterInterface) => {
        if (functions) {
            const activateFunc = functions[1]
            newsletter.active = true
            await activateFunc(newsletter)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer sx={{maxHeight: 440}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'actions' ? <Box>
                                                                <DeleteSharp sx={{cursor: 'pointer'}} onClick={() => {handleDelete(row)}}/>
                                                                <NotificationsActiveSharp color={row.active ? 'primary' : 'inherit'} sx={{cursor: 'pointer'}} onClick={() => {handleActivate(row)}}/>
                                                            </Box> :
                                                            <Typography fontWeight={'bold'}>{column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}</Typography> }
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </ThemeProvider>
    );
}